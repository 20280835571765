import { FC } from "react";
import Modal from "react-modal";
import { CallIcon, MailIcon, XMarkIcons } from "../assets/icons/icons";
import { ImagesPath } from "../assets/images/ImagesPath";

type PdfProps = {
  openModal: boolean;
  closeModal: () => void;
};

const SupportUsModal: FC<PdfProps> = ({ openModal, closeModal }) => {
  return (
    <Modal
      isOpen={openModal}
      className={"modal-width"}
      onRequestClose={closeModal}
      overlayClassName={"modal-background z-index-1"}
      ariaHideApp={false}
    >
      {/* Modal content for displaying PDF document */}
      <div className="relative w-full max-h-full" data-testid="guardsChatModal">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-2">
          <div className="flex justify-between items-center mb-2 px-3 pt-3">
            {/* Modal title */}
            <p className="text-lg font-medium text-primary">For Support</p>
            {/* Close button */}
            <button
              className="styles_closeIcon__1QwbI"
              onClick={() => {
                closeModal();
              }}
              data-testid="closeBtn"
            >
              <XMarkIcons />
            </button>
          </div>
          <div className="border border-black/10 mb-6"></div>
          <div className="max-w-7xl mx-auto text-center px-6">
            <div className="space-y-6">
              <div>
                <h4 className="flex justify-center items-center font-normal text-gray-900 text-md leading-snug ">
                  Reach us via&nbsp;
                  <MailIcon />
                </h4>
                <a
                  href="mailto:mosleyj@fidosecurity.com"
                  className="text-xl leading-snug font-normal text-primary"
                >
                  mosleyj@fidosecurity.com
                </a>
              </div>
              <div>
                <h4 className="flex justify-center items-center font-normal text-gray-900 text-md leading-snug">
                  Call us on&nbsp;
                  <CallIcon />
                </h4>
                <h4 className="text-lg leading-snug font-normal text-primary">
                  +1 205-532-2602
                </h4>
              </div>
            </div>
            <div className="w-full mt-4">
              <img
                src={ImagesPath.CONTACT_US}
                alt=""
                className="m-auto h-full mb-5"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SupportUsModal;
