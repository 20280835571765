import { useForm } from "react-hook-form";
import { ImagesPath } from "../assets/images/ImagesPath";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginSchema } from "../assets/schema";
import { Path } from "../routes/Path";
import { Separator, SupportIcon } from "../assets/icons/icons";
import { useState } from "react";
import SupportUsModal from "./SupportUsModal";

type FormData = {
  email: string;
};
const Home = () => {
  const [open, setOpen] = useState(false);
  const handleLogin = async (data: FormData) => {
    console.log(data);
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<FormData>({
    resolver: yupResolver(LoginSchema),
  });

  console.log(open, "??open");
  return (
    <>
      <nav id="header" className="absolute w-full z-30 top-0 text-white">
        <div className="w-full container mx-auto flex flex-wrap items-center">
          <div className="flex items-center px-8 py-4">
            <img src={ImagesPath.LOGO_WHITE} alt="" className="m-auto logo" />
          </div>
        </div>
        <hr className="bg-gray-600 opacity-25 my-0 mx-8" />
      </nav>
      <div className="bg flex items-center justify-center text-center">
        <div className=" text-light w-full p-5 sm:p-10 md:max-w-4xl">
          <h1 className="font-heading font-normal uppercase xl:text-[64px] lg:text-6xl sm:text-5xl text-4xl leading-tight mb-2 md:mb-4">
            FIND AND BOOK PRIVATE SECURITY WHEN YOU NEED IT
          </h1>
          <p className="text-2xl text-light">Security, simplified.</p>
          <button
            type="button"
            className="bg-white text-black text-base font-bold uppercase py-4 px-8 mt-5 rounded inline-block sm:mt-8 tracking-[0.071em]"
          >
            <a href="#contact">Contact us</a>
          </button>
        </div>
      </div>
      <section id="about" className="bg-primary md:p-14 p-10">
        <div className="max-w-7xl mx-auto text-center xl:px-6 px-0">
          <h2 className="font-heading font-normal text-white uppercase sm:text-5xl text-4xl leading-snug">
            ABOUT FIDO
          </h2>
          <div className="flex mt-6 mb-10 justify-center">
            <div className="w-[50px] h-[1.6px] bg-stroke inline-flex"></div>
          </div>
          <div className="grid lg:grid-cols-3 gap-8">
            <div className="mb-6 sm:mb-0">
              <div className="w-full rounded-full">
                <img
                  src={ImagesPath.ABOUT_IMG01}
                  alt=""
                  className="m-auto rounded-full"
                />
              </div>
              <div className="flex-grow mt-6">
                <h2 className="text-white text-2xl mb-6 leading-tight">
                  Our Experience
                </h2>
                <p className="leading-normal text-lg text-gray-700 px-4">
                  Fido was founded by law enforcement, for law enforcement.
                  Access off-duty law enforcement officers and private security
                  guards at the click of a button.
                </p>
              </div>
            </div>
            <div className="mb-6 sm:mb-0">
              <div className="w-full rounded-full">
                <img
                  src={ImagesPath.ABOUT_IMG02}
                  alt=""
                  className="m-auto rounded-full"
                />
              </div>
              <div className="flex-grow mt-6">
                <h2 className="text-white text-2xl mb-6 leading-tight">
                  Our Team
                </h2>
                <p className="leading-normal text-lg text-gray-700 px-4">
                  At Fido, you will have access to pre-cleared law enforcement
                  officers and licensed private security guards.
                </p>
              </div>
            </div>
            <div className="mb-6 sm:mb-0 mobile-view">
              <div className="w-full rounded-full">
                <img
                  src={ImagesPath.ABOUT_IMG03}
                  alt=""
                  className="m-auto rounded-full"
                />
              </div>
              <div className="flex-grow mt-6 title">
                <h2 className="text-white text-2xl mb-6 leading-tight">
                  Our Services
                </h2>
                <p className="leading-normal text-lg text-gray-700 px-4">
                  Select guards based on job location and criteria. Manage,
                  contact, and pay your guards in one platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact" className="bg-light md:p-14 py-10 px-0">
        <div className="max-w-7xl mx-auto text-center px-6">
          <div className="font-heading font-normal text-primary uppercase sm:text-5xl text-4xl leading-snug">
            CONTACT US
          </div>
          <div className="flex mt-6 mb-10 justify-center">
            <div className="w-[50px] h-[1.6px] bg-[#dadada] inline-flex"></div>
          </div>
          <div className="space-y-6">
            <h4 className="font-normal text-gray-900 text-2xl leading-snug">
              Drop us a line!
            </h4>
            <form
              className="max-w-3xl mx-auto space-y-6"
              onSubmit={handleSubmit(handleLogin)}
            >
              <div className="floating-input relative">
                <input
                  type="Name"
                  id="Name"
                  className="border border-gray-500 text-gray-800 focus:outline-none rounded focus:ring-1 focus:ring-gray-800 focus:shadow-inner w-full p-3 h-14 text-lg"
                  placeholder="Name"
                  autoComplete="off"
                />
                <label
                  htmlFor="Name"
                  className="field text-gray-800 text-lg absolute top-0 left-0 px-3 py-4 h-full pointer-events-none transform origin-left transition-all duration-100 ease-in-out"
                >
                  Name
                </label>
              </div>
              <div className="floating-input relative">
                <input
                  aria-label="email"
                  type="email"
                  id="email"
                  {...register("email")}
                  className="border border-gray-500 text-gray-800 focus:outline-none rounded focus:ring-1 focus:ring-gray-800 focus:shadow-inner w-full p-3 h-14 text-lg"
                  placeholder="Email*"
                  autoComplete="off"
                />
                <label
                  htmlFor="email"
                  className="field text-gray-800 text-lg absolute top-0 left-0 px-3 py-4 h-full pointer-events-none transform origin-left transition-all duration-100 ease-in-out"
                >
                  Email*
                </label>
                {isSubmitted && errors.email && (
                  <p className="text-danger mt-1 text-left">
                    {errors?.email?.message}
                  </p>
                )}
              </div>
              <textarea
                id="about"
                name="about"
                rows={6}
                placeholder="Message"
                className="block w-full rounded border border-gray-500 p-3 text-lg text-gray-800 focus:outline-none focus:ring-1 focus:ring-gray-800 focus:shadow-inner placeholder:text-gray-800"
              ></textarea>
              {/* <div className="flex justify-between items-center pb-4">
                <div className="flex">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md bg-transparent text-lg text-primary"
                  >
                    <span className="flex gap-1 items-center">
                      <svg
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        width="1em"
                        height="1em"
                        data-ux="Icon"
                        data-aid="paperclip"
                        className="w-5 h-5"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M15.6 11.6L17 13l-5.9 5.9c-1.3 1.3-2.9 1.9-4.6 1.9-1.7 0-3.2-.7-4.5-2-2.5-2.5-2.5-6.6 0-9.2l7.1-7.1.1-.1c1.9-2 5.1-2 7.1 0l.1.1c2 2 2 5.1 0 7.1l-6.9 6.6c-.7.7-1.6 1.1-2.5 1.2h-.3c-.9 0-1.7-.3-2.2-.9-.7-.7-1-1.6-.9-2.6.1-.9.5-1.8 1.2-2.5l5.5-5.8L11.7 7l-5.5 5.9c-.4.4-.6.8-.6 1.3 0 .2 0 .7.3 1 .3.3.7.3 1 .3.4 0 .9-.3 1.3-.6L15 8.2C16.2 7 16.2 5.1 15 4l-.1-.1c-1.2-1.2-3.1-1.2-4.3 0l-.7.7-6.5 6.5c-1.8 1.8-1.8 4.6 0 6.3 1.8 1.7 4.6 1.8 6.3 0l5.9-5.8z"
                        ></path>
                      </svg>
                      Attach Files
                    </span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                    />
                  </label>
                </div>
                <span className="text-gray-400 text-sm">Attachments (0)</span>
              </div> */}
              <div className="flex justify-end">
                <button className="bg-black text-white px-6 py-2 rounded text-base uppercase font-bold hover:bg-gray-600  md:w-auto w-full">
                  Send
                </button>
              </div>
            </form>
            <p className="text-gray-400 text-sm pt-3 mb-4">
              This site is protected by reCAPTCHA and the Google&nbsp;
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                className="text-primary"
                rel="noreferrer"
              >
                Privacy Policy&nbsp;
              </a>
              and&nbsp;
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                className="text-primary"
                rel="noreferrer"
              >
                Terms of Service&nbsp;
              </a>
              apply.
            </p>
          </div>
        </div>

        <div className="flex justify-center items-center w-full mt-4">
          <button
            onClick={() => setOpen(true)}
            className="flex flex-row items-center gap-1 text-primary
          rounded-md bg-primary/10 px-4 py-2 text-sm font-semibold ring-inset ring-primary/10 text-primar"
          >
            <SupportIcon /> For Support
          </button>
        </div>
      </section>

      <section className="p-14 bg-[#161616]">
        <div className="max-w-7xl mx-auto px-6">
          <div className="flex justify-center items-center text-center">
            <p className="text-[#a9a9a9] lg:text-base text-sm">
              Copyright © 2023 Fido - All Rights Reserved.
            </p>
          </div>
          <div className="flex flex-row text-[#a9a9a9] justify-center mt-4 gap-1 text-xs">
            <a href={Path.PRIVACY} target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
            <Separator />
            <a href={Path.TERMS} target="_blank" rel="noreferrer">
              Terms and Conditions
            </a>
          </div>
        </div>
      </section>

      <SupportUsModal openModal={open} closeModal={() => setOpen(false)} />
    </>
  );
};

export default Home;
