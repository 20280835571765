import { Mutex } from "async-mutex";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { setUser } from "../features/userSlice";
import { fetchDomainConfiguration } from "../../assets/DomainConfiguration";
import { RootState } from "../store";

const fetchDomains = fetchDomainConfiguration();
const baseUrl = fetchDomains?.api_url;

// Create a new mutex
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token: any | null = (getState() as RootState).userState.user;
    if (token) {
      const idToken =
        typeof token === "string"
          ? JSON.parse(token).idToken.jwtToken
          : token.idToken.jwtToken;
      headers.set("Authorization", idToken);
    }
    return headers;
  },
});

const customFetchBase = async (args: any, api: any, extraOptions: any) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  if ((result.error?.data as any)?.code === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await fetch(`${baseUrl}auth/refresh-tokens`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refreshToken: api.getState().userState?.user?.tokens?.refresh.token,
          }),
        });
        let refreshedData = await refreshResult.json();
        if (refreshedData.data) {
          // Retry the initial query
          // update on the store
          api.dispatch(setUser(refreshedData.data));
          result = await baseQuery(args, api, extraOptions);
        } else {
          //  navigate to home page
          window.location.href = "/auth/login";
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export default customFetchBase;
