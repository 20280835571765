import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "./api/authApi";
import userReducer from "./features/userSlice";

// Configure the Redux store using configureStore from Redux Toolkit
export const store = configureStore({
  reducer: {
    userState: userReducer, // Assign the userReducer to manage the userState in the store
    // Assign reducers and paths for various API services
    [authApi.reducerPath]: authApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat(
      // Concatenate middleware for various API services
      authApi.middleware
    ),
});

export type AppDispatch = typeof store.dispatch; // Define the type for dispatching actions from the store
export type RootState = ReturnType<typeof store.getState>; // Define the type for the root state of the store
