import { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = "Fido || Privacy Policy";
  });
  return (
    <div className="p-5">
      <h1 className="font-bold text-4xl text-center py-3">Privacy Policy</h1>
      <p className="font-bold">
        Effective Date:{" "}
        <span className="font-normal text-gray-800">January 1, 2024</span>
      </p>
      <p className="py-3 text-gray-800">
        Welcome to the Fido Security Mobile App ("we," "us," or "our"). This
        Privacy Policy is designed to help you understand how we collect, use,
        share, and safeguard your personal information when you use our
        marketplace app and services. By accessing or using the Fido Security
        Mobile App, you agree to the terms of this Privacy Policy.
      </p>
      <ol className="heading-list px-6">
        <li>
          <h2 className="font-bold py-3 text-2xl">
            1. Information We Collect:
          </h2>
          <p className="py-3 px-6 text-gray-800">
            We collect both personal and non-personal information to provide and
            improve our services. The types of information we collect may
            include:
          </p>
          <ul className="subheading-list flex flex-col gap-1 px-6 py-3">
            <li>
              <h1 className="font-bold">a. Personal Information:</h1>
              <ul className="subheading-list flex flex-col gap-2 px-6 py-3 text-gray-800">
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 inline-block rounded-full bg-primary" />
                  Your name, contact information, and address.
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 inline-block rounded-full bg-primary" />
                  Your profile information, including a photograph and
                  professional qualifications.
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 inline-block rounded-full bg-primary" />
                  Payment information for transaction processing.
                </li>
              </ul>
            </li>
            <li>
              <h1 className="font-bold">b. Non-Personal Information:</h1>
              <ul className="subheading-list flex flex-col gap-2 px-6 py-3 text-gray-800">
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 inline-block rounded-full bg-primary" />
                  Aggregated and anonymized data regarding app usage patterns.
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 inline-block rounded-full bg-primary" />
                  Device information, including hardware model, operating
                  system, and unique device identifiers.
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 inline-block rounded-full bg-primary" />
                  Log information, such as your IP address, browser type, and
                  access times.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">
            2. How We Use Your Information:
          </h2>
          <p className="py-3 px-6 text-gray-800">
            We use the information collected for various purposes, including but
            not limited to:
          </p>
          <ul className="subheading-list flex flex-col gap-2 px-6 py-3 text-gray-800">
            <li>
              <h1 className="font-bold">a. Service Provision:</h1>
              <ul className="subheading-list flex flex-col gap-2 px-6 py-3 text-gray-800">
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 inline-block rounded-full bg-primary" />
                  Facilitating the connection between security guards and
                  companies seeking security services.
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 inline-block rounded-full bg-primary" />
                  Processing transactions and providing customer support.
                </li>
              </ul>
            </li>
            <li>
              <h1 className="font-bold">b. Communication:</h1>
              <ul className="subheading-list flex flex-col gap-2 px-6 py-3 text-gray-800">
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 inline-block rounded-full bg-primary" />
                  Sending important notices, such as updates, security alerts,
                  and administrative messages.
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 inline-block rounded-full bg-primary" />
                  Responding to inquiries and providing support.
                </li>
              </ul>
            </li>
            <li>
              <h1 className="font-bold">
                c. Improving and Personalizing Services:
              </h1>
              <ul className="subheading-list flex flex-col gap-2 px-6 py-3 text-gray-800">
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 inline-block rounded-full bg-primary" />
                  Analyzing usage patterns to enhance the functionality and user
                  experience of our app.
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 inline-block rounded-full bg-primary" />
                  Customizing content and recommendations based on user
                  preferences.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">3. Information Sharing:</h2>
          <p className="py-3 px-6 text-gray-800">
            We may share your information under the following circumstances:
          </p>
          <ul className="subheading-list flex flex-col gap-2 px-6 py-3 text-gray-800">
            <li>
              <h1 className="font-bold">a. With Security Service Customers:</h1>
              <ul className="subheading-list flex flex-col gap-2 px-6 py-3 text-gray-800">
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 inline-block rounded-full bg-primary" />
                  Sharing your profile information with companies and
                  individuals seeking security services.
                </li>
              </ul>
            </li>
            <li>
              <h1 className="font-bold">
                b. With Third-Party Service Providers:
              </h1>
              <ul className="subheading-list flex flex-col gap-2 px-6 py-3 text-gray-800">
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 inline-block rounded-full bg-primary" />
                  Utilizing third-party services for payment processing,
                  analytics, and other support functions.
                </li>
              </ul>
            </li>
            <li>
              <h1 className="font-bold">c. Legal Compliance:</h1>
              <ul className="subheading-list flex flex-col gap-2 px-6 py-3 text-gray-800">
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 inline-block rounded-full bg-primary" />
                  Complying with applicable laws, regulations, or legal
                  processes.
                </li>
              </ul>
            </li>
            <li>
              <h1 className="font-bold">d. Business Transactions:</h1>
              <ul className="subheading-list flex flex-col gap-2 px-6 py-3 text-gray-800">
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 inline-block rounded-full bg-primary" />
                  Sharing information in connection with a merger, acquisition,
                  or sale of all or a portion of our assets.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">4. Your Choices:</h2>
          <p className="py-3 px-6 text-gray-800">
            You can control certain aspects of your information:
          </p>
          <ul className="subheading-list flex flex-col gap-2 px-6 py-3 text-gray-800">
            <li>
              <h1 className="font-bold">a. Profile Information:</h1>
              <ul className="subheading-list flex flex-col gap-2 px-6 py-3 text-gray-800">
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 inline-block rounded-full bg-primary" />
                  You can update or delete your profile information at any time.
                </li>
              </ul>
            </li>
            <li>
              <h1 className="font-bold">a. Marketing Communications:</h1>
              <ul className="subheading-list flex flex-col gap-2 px-6 py-3 text-gray-800">
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 inline-block rounded-full bg-primary" />
                  You can opt-out of receiving promotional emails by following
                  the instructions in those emails.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">5. Security:</h2>
          <p className="py-3 px-6 text-gray-800">
            We implement reasonable security measures to protect your
            information from unauthorized access, disclosure, alteration, or
            destruction.
          </p>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">6. Children's Privacy:</h2>
          <p className="py-3 px-6 text-gray-800">
            Our services are not directed at individuals under the age of 18. If
            we become aware that personal information of a child has been
            collected without parental consent, we will take steps to delete
            such information.
          </p>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">
            7. Changes to this Privacy Policy:
          </h2>
          <p className="py-3 px-6 text-gray-800">
            We may update this Privacy Policy to reflect changes in our
            practices or for other operational, legal, or regulatory reasons. We
            will notify you of any material changes through the app or other
            means.
          </p>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">8. Contact Us:</h2>
          <div className="flex flex-col gap-2 px-6">
            <p className="text-gray-800">
              If you have any questions or concerns about this Privacy Policy,
              please contact us at{" "}
              <a
                href="mailto:admin@fidosecurity.com"
                className="text-secondary underline"
              >
                admin@fidosecurity.com
              </a>
              .
            </p>
            <p className="text-gray-800">
              Thank you for using the Fido Security Mobile App!
            </p>
          </div>
        </li>
      </ol>
      <div className="footer text-center mt-6 mb-4 text-gray-800">
        <p>Privacy Policy for Fido Security Mobile App - &copy; 2024</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
