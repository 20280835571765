import { createSlice } from "@reduxjs/toolkit";

// Define initial state for the user slice, fetching user from local storage if available
const initialState = {
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") || "")
    : null,
};

// Create a user slice using createSlice from Redux Toolkit
export const userSlice = createSlice({
  initialState, // Set the initial state for the user slice
  name: "user", // Define the name of the slice as "user"
  reducers: {
    // Define a reducer function to save the AWS user to the state and local storage
    setUser: (state, action) => {
      state.user = action.payload; // Update the user in the state with the provided payload
      localStorage.setItem("user", action.payload); // Store the user in local storage
    },
  },
});

export default userSlice.reducer; // Export the reducer from the user slice

export const { setUser } = userSlice.actions; // Export the action creator for saving AWS user

// Define a selector function to retrieve the user state from the Redux store
export const currentUser = (state: any) => state.user;
