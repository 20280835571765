// Defining a constant object to hold paths for various images
export const ImagesPath = {
  LOGO: require("./logo.png"),
  LOGO_WHITE: require("./logo_white.png"),
  ABOUT_IMG01: require("./about_img01.png"),
  ABOUT_IMG02: require("./about_img02.png"),
  ABOUT_IMG03: require("./about_img03.png"),
  CONTACT_US: require("./Contactus.png"),
  SPIN: require("./Spin.gif"),
};
