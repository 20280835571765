import { ImagesPath } from "../assets/images/ImagesPath";

const Redirect = () => {
  return (
    <>
      <div className="bg-white flex items-center justify-center text-center h-screen">
        <img src={ImagesPath.SPIN} alt="" className="m-auto rounded-full w-40 h-40" />
      </div>
    </>
  );
};

export default Redirect;
