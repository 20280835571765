import * as yup from "yup";
import { Constants } from "../constants/constants";
// Login schema
export const LoginSchema = yup.object().shape({
    email: yup
      .string()
      .required(Constants?.IsEmailEmpty) // Email field required validation
      .max(100, Constants?.IsEmailLength) // Maximum length validation for email
      .email(Constants?.IsEmailInvalid) // Email format validation
      .test("valid-domain", "Please enter a valid email address.", (value:any) => {
        if (!value) return false; // Empty value considered invalid
        const domainRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/; // Regex for validating domain
        return domainRegex.test(value);
      }),
  });