import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { Path } from "./routes/Path";
import Redirect from "./pages/Redirect";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path={Path.HOME} element={<Home />} />
        <Route path={Path.REDIRECT} element={<Redirect />} />
        <Route path={Path.TERMS} element={<TermsConditions />} />
        <Route path={Path.PRIVACY} element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
};

export default App;
