import { useEffect } from "react";

const TermsConditions = () => {
  useEffect(() => {
    document.title = "Fido || Terms & Conditions";
  });
  return (
    <div className="p-5">
      <h1 className="font-bold text-4xl text-center py-3">Terms of Service</h1>
      <p className="font-bold">
        Last Updated:{" "}
        <span className="font-normal text-gray-800">January 1, 2024</span>
      </p>
      <p className="py-3 text-gray-800">
        Welcome to Fido Security Marketplace App ("Fido Security" or "App"). The
        following Terms of Service ("Terms") outline the legal agreement between
        Fido Security and its users. By accessing or using the Fido Security
        App, you agree to comply with and be bound by these Terms. Please read
        them carefully before using the App.
      </p>
      <ol className="heading-list px-6">
        <li>
          <h2 className="font-bold py-3 text-2xl">1. Acceptance of Terms</h2>
          <p className="py-3 px-6 text-gray-800">
            By using the Fido Security App, you acknowledge that you have read,
            understood, and agree to be bound by these Terms, as well as our
            Privacy Policy. If you do not agree with any part of these Terms,
            you may not use the App.
          </p>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">2. Description of Service</h2>
          <p className="py-3 px-6 text-gray-800">
            Fido Security is a marketplace app that enables law enforcement
            officers and private security guards in Alabama to create profiles
            and allows companies and individuals to create profiles, search for,
            and hire available guards based on selected criteria.
          </p>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">3. Eligibility</h2>
          <p className="py-3 px-6 text-gray-800">
            You must be at least 19 years old and have the legal capacity to
            enter into agreements to use the Fido Security App. In addition, you
            must either be an APOST certified sworn law enforcement officer, or
            a private security guard licensed by the Alabama Security Regulatory
            Board and in compliance with all applicable laws and regulations for
            such licensing. By using the App, you represent and warrant that you
            meet these eligibility requirements.
          </p>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">4. User Accounts</h2>
          <ol className="subheading-list flex flex-col gap-1 px-6 py-3">
            <li>
              <p className="font-bold">
                a. User Registration:{" "}
                <span className="font-normal">
                  To use certain features of the App, you must register and
                  create a user account. You agree to provide accurate and
                  complete information during the registration process.
                </span>
              </p>
            </li>
            <li>
              <p className="font-bold">
                b. Security:{" "}
                <span className="font-normal">
                  You are responsible for maintaining the confidentiality of
                  your account credentials and for all activities that occur
                  under your account. Notify Fido Security immediately of any
                  unauthorized use of your account or any other breach of
                  security.
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">5. User Profiles</h2>
          <ol className="subheading-list flex flex-col gap-1 px-6 py-3">
            <li>
              <p className="font-bold">
                a. Accuracy:{" "}
                <span className="font-normal">
                  Users are responsible for ensuring the accuracy of the
                  information provided in their profiles. Fido Security is not
                  responsible for any inaccuracies in user profiles.
                </span>
              </p>
            </li>
            <li>
              <p className="font-bold">
                b. Verification:{" "}
                <span className="font-normal">
                  Fido Security may, at its discretion, implement a verification
                  process to confirm the identity and credentials of law
                  enforcement officers and private security guards.
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">6. Use of the App</h2>
          <ol className="subheading-list flex flex-col gap-1 px-6 py-3">
            <li>
              <p className="font-bold">
                a. Lawful Purpose:{" "}
                <span className="font-normal">
                  Users agree to use the Fido Security App for lawful purposes
                  only. Users shall not engage in any activity that violates
                  applicable laws or regulations.
                </span>
              </p>
            </li>
            <li>
              <p className="font-bold">
                b. Prohibited Content:{" "}
                <span className="font-normal">
                  Users shall not post, upload, or share any content that is
                  illegal, offensive, fraudulent, or violates the rights of
                  others.
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">7. Transactions and Fees</h2>
          <ol className="subheading-list flex flex-col gap-1 px-6 py-3">
            <li>
              <p className="font-bold">
                a. Transaction Responsibility:{" "}
                <span className="font-normal">
                  Fido Security is not involved in the transactions between
                  users. Users are solely responsible for any transactions
                  conducted through the App.
                </span>
              </p>
            </li>
            <li>
              <p className="font-bold">
                b. Fees:{" "}
                <span className="font-normal">
                  Fido Security may charge fees for certain services provided
                  through the App. Users will be notified of any applicable fees
                  before using such services.
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">8. Termination of Service</h2>
          <p className="py-3 px-8 text-gray-800">
            Fido Security reserves the right to suspend or terminate the App or
            any users access at any time for any reason, including, but not
            limited to, violation of these Terms.
          </p>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">9. Changes to Terms</h2>
          <p className="py-3 px-8 text-gray-800">
            Fido Security reserves the right to modify or update these Terms at
            any time. Users will be notified of changes, and continued use of
            the App after modifications constitutes acceptance of the updated
            Terms.
          </p>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">
            10. Disclaimer of Warranties
          </h2>
          <p className="py-3 px-8 text-gray-800">
            The Fido Security App is provided "as is" without warranties of any
            kind. Fido Security makes no warranties, express or implied,
            regarding the accuracy, reliability, or availability of the App.
          </p>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">
            11. Limitation of Liability
          </h2>
          <p className="py-3 px-8 text-gray-800">
            Fido Security shall not be liable for any indirect, incidental,
            special, or consequential damages arising out of or in connection
            with the use of the App.
          </p>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">12. Governing Law</h2>
          <p className="py-3 px-8 text-gray-800">
            These Terms shall be governed by and construed in accordance with
            the laws of the state of Alabama.
          </p>
        </li>
        <li>
          <h2 className="font-bold py-3 text-2xl">13. Contact Information</h2>
          <div className="flex flex-col gap-2 px-9">
            <p className="text-gray-800">
              If you have any questions or concerns about these Terms, please
              contact us at{" "}
              <a
                href="mailto:admin@fidosecurity.com"
                className="text-secondary underline"
              >
                admin@fidosecurity.com
              </a>
              .
            </p>
            <p className="text-gray-800">
              By using the Fido Security App, you acknowledge that you have
              read, understood, and agree to abide by these Terms of Service.
            </p>
          </div>
        </li>
      </ol>
      <div className="footer text-center mt-6 mb-4 text-gray-800">
        <p>Fido Security Marketplace App - &copy; 2024</p>
      </div>
    </div>
  );
};

export default TermsConditions;
